import { ORDER_DATA } from "../actions/orderAction";

const initialState = {
  additional_informations: null,
  billing_address: {},
  channel_currency_code: "BRL",
  channel_name: "Default",
  coupon_code: null,
  created_at: "",
  created_at_formatted: "",
  customer: null,
  customer_email: "",
  customer_first_name: "",
  customer_full_name: "",
  customer_last_name: "",
  corporate_name: "",
  state_registration: "",
  customer_orders_qty: 0,
  discount_amount: "0.0000",
  discount_invoiced: "0.0000",
  discount_percent: "0.0000",
  discount_refunded: "0.0000",
  grand_total: "0.00",
  grand_total_invoiced: "0.0000",
  grand_total_refunded: "0.0000",
  id: null,
  invoices: [],
  is_gift: 0,
  is_guest: 1,
  items: [],
  observations: null,
  order_currency_code: "BRL",
  payment_status: "",
  payment_status_label: "",
  shipments: [],
  shipping_address: {
    additional: null,
    address1: "",
    address2: "",
    address_type: "order_shipping",
    cart_id: null,
    city: "",
    company_name: null,
    country: "",
    created_at: "",
    customer_id: null,
    default_address: 0,
    email: "",
    first_name: "",
    gender: null,
    id: null,
    last_name: "",
    neighborhood: "",
    number: "",
    order_id: null,
    phone: "",
    postcode: "",
    state: "",
    updated_at: "",
  },
  vat_id: null,
  shipping_amount: "0.00",
  shipping_description: null,
  shipping_invoiced: "0.0000",
  shipping_method: "0",
  shipping_refunded: "0.0000",
  shipping_title: "",
  status: "",
  status_label: "",
  sub_total: "0.0000",
  sub_total_invoiced: "0.0000",
  sub_total_refunded: "0.0000",
  tags: [],
  tax_amount: "0.0000",
  tax_amount_invoiced: "0.0000",
  tax_amount_refunded: "0.0000",
  total_item_count: 0,
  total_qty_ordered: 0,
  updated_at: "",
  updated_at_formatted: "",
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_DATA:
      return action.payload;
    default:
      return state;
  }
}
