import React from "react";
import { connect } from "react-redux";

const Footer = ({ config }) => {
  let cpf_display = " ";
  if (!!config.cpf_cnpj) {
    cpf_display = ` - ${config.cpf_cnpj.length > 14 ? "CNPJ" : "CPF"}: ${
      config.cpf_cnpj
    } `;
  }

  return (
    <footer className="w-100 pb-2 pt-2 bg-white d-flex justify-content-center align-items-center">
      <div className="d-flex flex-column align-items-center">
        <div className="font-12 text-center mb-3 mt-1">
          <a className="text-dark" href="/" target="_blank">
            {config.owner_name}
            {cpf_display}
          </a>
          &copy; Todos os direitos reservados
          {" " + new Date().getFullYear() + " "}
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => {
  return {
    config: state.configData,
  };
};

export default connect(mapStateToProps)(Footer);
