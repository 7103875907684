import { combineReducers } from "redux";
import cartDataReducer from "./cartDataReducer";
import { orderReducer } from "./orderReducer";
import { configDataReducer } from "./configDataReducer";


const rootReducer = combineReducers({
  cartData: cartDataReducer,
  orderData: orderReducer,
  configData: configDataReducer
})

export default rootReducer;
