export const ORDER_DATA = 'ORDER_DATA';

export const orderData = (data) => {
  return dispatch => {
    return dispatch({
      type: ORDER_DATA,
      payload: data
    })
  }
}
