import { CONFIG_DATA } from "../actions/configDataAction"

const initialState = {
  json_settings: {
    themes: '',
    logo: {
      alt: '',
      src: ''
    },
    owner_name: ''
  }
}

export const configDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIG_DATA:
      return action.payload;
    default:
      return state;
  }
}
