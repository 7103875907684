import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Checkout from './views/Checkout';
import Confirmation from './views/Confirmation';

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact={true} path="/checkout" component={Checkout} />
        <Route exact={true} path="/checkout/:payment_token" component={Checkout} />
        <Route exact={true} path="/checkout/confirmation/:payment_token" component={Confirmation} />
        <Redirect from="/" to="/checkout" />
      </Switch>
    </BrowserRouter>
  )
}

export default Router;
