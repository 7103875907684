import React, { Fragment, useCallback, useRef, useState, useEffect } from 'react';
import Card from './components/card';
import CForm from './components/form';

const initialState = {
    cardNumber: '#### #### #### ####',
    cardHolder: 'FULL NAME',
    cardBrand: 'FULL NAME',
    cardMonth: '',
    cardYear: '',
    cardCvv: '',
    isCardFlipped: false
};

const MainScreen = ({ onChange }) => {
    const [state, setState] = useState(initialState);
    const [currentFocusedElm, setCurrentFocusedElm] = useState(null);

    const CARDS = {
        visa: '^4',
        amex: '^(34|37)',
        mastercard: '^5[1-5]',
        discover: '^6011',
        unionpay: '^62',
        troy: '^9792',
        diners: '^(30[0-5]|36)'
    };

    const cardType = useCallback((cardNumber) => {
        const number = cardNumber;
        let re;
        for (const [card, pattern] of Object.entries(CARDS)) {
            re = new RegExp(pattern);
            if (number.match(re) != null) {
                return card;
            }
        }

        return 'visa'; // default type
    }, [CARDS])

    const updateStateValues = useCallback(
        (keyName, value) => {
            if (keyName === 'cardNumber') {
                setState({
                    ...state,
                    cardNumber: value || initialState['cardNumber'],
                    cardBrand: cardType(value) || initialState['cardBrand'],
                });
            } else {
                setState({
                    ...state,
                    [keyName]: value || initialState[keyName]
                });
            }
        },
        [cardType, state]
    );

    useEffect(() => {
        onChange(state)
    }, [onChange, state])

    // References for the Form Inputs used to focus corresponding inputs.
    let formFieldsRefObj = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef(),
        cardCvv: useRef(),
        cardCPF: useRef()
    };

    let focusFormFieldByKey = useCallback((key) => {
        formFieldsRefObj[key].current.focus();
    }, [formFieldsRefObj]);

    // This are the references for the Card DIV elements.
    let cardElementsRef = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef(),
        cardCPF: useRef(),
    };

    let onCardFormInputFocus = (_event, inputName) => {
        const refByName = cardElementsRef[inputName];
        setCurrentFocusedElm(refByName);
    };

    let onCardInputBlur = useCallback(() => {
        setCurrentFocusedElm(null);
    }, []);

    return (
        <Fragment>
            <CForm
                cardMonth={state.cardMonth}
                cardYear={state.cardYear}
                onUpdateState={updateStateValues}
                cardNumberRef={formFieldsRefObj.cardNumber}
                cardHolderRef={formFieldsRefObj.cardHolder}
                cardDateRef={formFieldsRefObj.cardDate}
                cardCpfRef={formFieldsRefObj.cardCPF}
                onCardInputFocus={onCardFormInputFocus}
                onCardInputBlur={onCardInputBlur}
            >
                <Card
                    cardNumber={state.cardNumber}
                    cardHolder={state.cardHolder}
                    cardMonth={state.cardMonth}
                    cardYear={state.cardYear}
                    cardCvv={state.cardCvv}
                    isCardFlipped={state.isCardFlipped}
                    currentFocusedElm={currentFocusedElm}
                    onCardElementClick={focusFormFieldByKey}
                    cardNumberRef={cardElementsRef.cardNumber}
                    cardHolderRef={cardElementsRef.cardHolder}
                    cardDateRef={cardElementsRef.cardDate}
                />
            </CForm>
        </Fragment>
    );
};

export default MainScreen;
