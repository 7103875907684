import React, { Fragment, useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'
import { Card, Form, InputGroup, Button } from 'react-bootstrap'
import { FaChevronDown, FaChevronUp, FaTag, FaChevronRight } from 'react-icons/fa'
import { connect } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'
import { useToasts } from 'react-toast-notifications'
import api from '../../api/api'
import { cartData as responseCartData } from '../../store/actions/cartDataAction'

const CardProduct = ({ config, confirmationScreen, data, responseCartData, recalculateShipping = f => f }) => {

  const background_button = config?.json_settings?.general?.checkout?.buttons?.background
  const color_text = config?.json_settings?.general?.checkout?.buttons?.text

  let qty = 1

  const language = config?.json_settings?.general?.language;

  //variables translations
  const langEn = (language === 'en')
  const noProductFound = langEn ? 'No products found' : 'Nenhum Produto encontrado';
  const anyDiscountCupom = langEn ? 'Any coupon of discount?' : 'Possui um cupom de desconto?';
  const cupomCodeTranslation = langEn ? 'Code of discount coupon' : 'Código do cupom de desconto';
  const shippingTranslation = langEn ? 'Shipping' : 'Entrega';
  const totalDiscountTranslation = langEn ? 'Total Discount' : 'Desconto Total';
  const [show, setShow] = useState(false)
  const [showCouponCode, setShowCouponCode] = useState(!!data.coupon_code)
  const [applyingCoupon, setApplyingCoupon] = useState(false)
  const [couponCode, setCouponCode] = useState(data.coupon_code || '')
  const [subtotal, setSubtotal] = useState(0)
  const [total, setTotal] = useState(0)
  const [delivery, setDelivery] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [display_quantities, setDisplayQuantities] = useState(data.items ? data.items.map(item => {
    return {
      id: item.id,
      quantity: item.quantity ? item.quantity : item.qty_ordered
    }
  }) : [])

  const { addToast } = useToasts()

  const applyCouponCode = (e) => {
    e.preventDefault()

    if (!couponCode) {
      addToast('Insira o cupom', { appearance: 'error', autoDismiss: true })
      return
    }

    setApplyingCoupon(true)
    const headers = {}
    const tokenApi = localStorage.getItem('tokenApi')
    if (tokenApi) {
      headers.Authorization = `Bearer ${tokenApi}`
    }
    api.post('/checkout/cart/coupon', {
      code: couponCode
    }, {
      params: {
        payment_token: localStorage.getItem('paymentToken')
      },
      headers
    }).then(response => {
      if (response.data.success) {
        responseCartData(response.data.cart);
        addToast('Cupom adicionado com sucesso', { appearance: 'success', autoDismiss: true })
      } else {
        addToast('Cupom inválido ou já utilizado', { appearance: 'error', autoDismiss: true })
      }
    }).finally(() => setApplyingCoupon(false))
  }

  useEffect(() => {
    setSubtotal(data && parseFloat(data.sub_total));
    setTotal(data && parseFloat(data.grand_total));
    setDiscount(data && data.discount ? parseFloat(data.discount) : parseFloat(data.discount_amount));
    if (data && parseFloat(data.shipping_amount) > 0) {
      setDelivery(data && parseFloat(data.shipping_amount));
    } else if (data.selected_shipping_rate) {
      setDelivery(data && parseFloat(data.selected_shipping_rate.price));
    } else {
      setDelivery(0);
    }
    setShowCouponCode(!!data.coupon_code);
    setCouponCode(data.coupon_code || '');
  }, [data])

  const debouncedUpdate = useRef(debounce(payload => {
    return api.put('/checkout/cart/update', {
      qty: {
        [payload.item_id]: payload.quantity
      }
    }, {
      params: {
        payment_token: localStorage.getItem('paymentToken')
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('tokenApi')}`
      }
    }).then(response => {
      responseCartData(response.data.data);
      ReactPixel.fbq('track', 'AddToCart', {
        content_type: 'product_group',
        content_ids: response.data.data.items.map(item => item.child ? item.child.product.id : item.product.id),
        content_name: response.data.data.items.map(item => item.child ? item.child.name : item.name),
        value: response.data.data.sub_total,
        currency: 'BRL'
      })
      payload.recalculate();
    }).catch(() => {
      setDisplayQuantities(data.items.map(item => {
        if (item.id === payload.item_id) {
          item.quantity = payload.backup_quantity
        }
        return {
          id: item.id,
          quantity: item.quantity ? item.quantity : item.qty_ordered
        };
      }));
      addToast('Quantidade solicitada indisponível no momento', { appearance: 'error', autoDismiss: true })
    });
  }, 500)).current;

  function incrementQuantity(itemId, quantity) {
    const backup_quantity = quantity;
    setDisplayQuantities(data.items.map(item => {
      if (item.id === itemId) {
        item.quantity = quantity + 1;
        debouncedUpdate({
          quantity: quantity + 1,
          backup_quantity: backup_quantity,
          item_id: item.id,
          recalculate: recalculateShipping
        });
      }
      return {
        id: item.id,
        quantity: item.quantity ? item.quantity : item.qty_ordered
      };
    }));
  }

  const decrementQuantity = (itemId, quantity) => {
    const backup_quantity = quantity;
    if (quantity > 1) {
      setDisplayQuantities(data.items.map(item => {
        if (item.id === itemId) {
          item.quantity = quantity - 1
          debouncedUpdate({
            quantity: quantity - 1,
            backup_quantity: backup_quantity,
            item_id: item.id,
            recalculate: recalculateShipping
          });
        }
        return {
          id: item.id,
          quantity: item.quantity ? item.quantity : item.qty_ordered
        };
      }));
    }
  }

  return (
    <Fragment>
      <Card className="border-mobile border-top-0 mt-0 custom-card">
        <Card.Body>
          <div className={`desktop-only ${show && 'show'}`}>
            <table className="w-100 mb-2">
              <tbody>
                { data.items && data.items.map(item => {
                  let display_item = display_quantities.find(display => display.id === item.id);
                  if (!display_item && data.items.length > 0) {
                    const data_item = data.items.find(data_item => data_item.id === item.id);
                    display_item = {
                      id: data_item.id,
                      quantity: item.quantity ? item.quantity : item.qty_ordered
                    }
                  }
                  if (display_item && data.items.length > 0) {
                    let itemImage

                    if (item.child && Object.entries(item.child).length > 0 && item?.child?.product?.image?.url) {
                      itemImage = item.child.product.image.url
                    } else if (item?.product?.images?.length > 0 && item.product.images[0].url) {
                      itemImage = item.product.images[0].url
                    } else {
                      itemImage = `https://via.placeholder.com/50x50?text=SEM+FOTO`
                    }

                    return (
                      <tr hidden={qty <= 0} style={{ lineHeight: '80px' }} key={item.id}>
                        <td className="bg-light text-center" style={{ maxHeight: '60px', width: '60px' }}>
                          <img
                            src={itemImage}
                            alt="product"
                            style={{ minWidth: '40px', maxWidth: '50px' }}
                            className="w-100"
                          />
                        </td>
                        <td className="w-50 pl-3" style={{ lineHeight: '24px' }}>
                          <h5
                            className="text-gray m-0"
                            style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces'}}
                          >
                            { item.child ? item.child.name : item.name }
                          </h5>
                          <strong>{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.price) } x { display_item.quantity }</strong>
                        </td>
                        <td className="pr-3">
                          {!confirmationScreen && <div className="align-items-center justify-content-end w-100 d-flex">
                            <button
                              className="btn btn-text"
                              onClick={() => decrementQuantity(item.id, item.quantity ? item.quantity : item.qty_ordered)}
                            >
                              -
                            </button>
                            <input
                              className="form-control text-center"
                              type="text"
                              value={item.quantity ? item.quantity : item.qty_ordered}
                              readOnly
                              style={{ height: '30px', width: '60px' }}
                            />
                            <button
                              className="btn btn-text"
                              onClick={() => incrementQuantity(item.id, item.quantity ? item.quantity : item.qty_ordered)}
                            >
                              +
                            </button>
                          </div>}
                        </td>
                      </tr>
                    )
                  } else {
                    return <p> {noProductFound} </p>
                  }
                }) }
              </tbody>
            </table>
            <hr/>
            <table className="w-100">
              <tbody>
                <tr className="border-bottom">
                  <td>
                    <p className="m-0 text-muted font-normal">Subtotal</p>
                  </td>
                  <td className="text-right" style={{ width: 90 }}>
                    <h5 className="m-0">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(subtotal)}</h5>
                  </td>
                </tr>
                { data.applied_cart_rules && data.applied_cart_rules.filter(rule => {
                  return !rule.apply_to_shipping
                }).map(rule => (
                  <tr key={rule.id} className="text-info border-bottom">
                    <td>
                      <p className="m-0 font-normal">{rule.name}</p>
                    </td>
                    <td className="text-right">
                      {rule.action_type === 'by_percent' &&
                        <h5 className="m-0">-{Math.round( rule.discount_amount * 100 + Number.EPSILON ) / 100}%</h5>
                      }
                      {(rule.action_type === 'cart_fixed' || rule.action_type === 'by_fixed') &&
                        <h5 className="m-0">
                          -{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rule.discount_amount) }
                        </h5>
                      }
                    </td>
                  </tr>
                )) }
                { discount > 0 &&
                  <tr className="border-bottom">
                    <td>
                      <p className="m-0 text-muted font-normal">{totalDiscountTranslation}</p>
                    </td>
                    <td className="text-right text-info">
                      <h5 className="m-0"> -{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(discount) } </h5>
                    </td>
                  </tr>
                }
                <tr>
                  <td>
                    <p className="m-0 text-muted font-normal">{shippingTranslation}</p>
                  </td>
                  <td className="text-right">
                    <h5 className="m-0"> {
                      (!data.selected_shipping_rate && !confirmationScreen) || delivery > 0
                        ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(delivery)
                        : 'Grátis'
                    } </h5>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr/>
            <div className="d-flex justify-content-between">
              <p className="pt-1 m-0 text-muted font-normal">Total</p>
              <h2 className="total-font">{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total) }</h2>
            </div>
            { confirmationScreen ? '' : <div className="w-100 text-right desktop-only"></div> }
            { show ? <hr /> : '' }
          </div>
          <div className="d-flex justify-content-between mobile-only" onClick={ () => setShow(!show) }>
            <div>
              <h2 className="total-font">{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total) }</h2>
            </div>
            <div className="d-flex align-items-center" >
              { show ? <FaChevronUp /> : <FaChevronDown /> }
            </div>
          </div>
        </Card.Body>
      </Card>
      {!confirmationScreen && <div className="text-center mt-3">
        { !showCouponCode &&
        <Button
          block
          onClick={ () => setShowCouponCode(true) }
          style={{
            height: 42,
            backgroundColor: background_button,
            borderColor: background_button,
            color: color_text
           }}
        >
          <FaTag className="mr-2" />
          {anyDiscountCupom}
        </Button>}
        { showCouponCode && <Form onSubmit={ (e) => applyCouponCode(e) }>
          <InputGroup className="mb-3">
            <Form.Control
              disabled={applyingCoupon}
              placeholder={cupomCodeTranslation}
              value={couponCode}
              style={{ border: '1px solid rgb(118, 118, 118)' }}
              onChange={ event => setCouponCode(event.target.value.toUpperCase()) }
            />
            <InputGroup.Append>
              <Button
                variant="outline-dark"
                disabled={applyingCoupon}
                type="submit"
              >
                <FaChevronRight/>
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Form> }
      </div>}
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    cartData: state.cartData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    responseCartData: data => dispatch(responseCartData(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardProduct);