import { CART_DATA } from '../actions/cartDataAction';

export const initialState = {
  base_currency_code: "BRL",
  base_discount: "",
  base_grand_total: "",
  base_sub_total: "",
  base_tax_total: "",
  billing_address: {
    name: ''
  },
  cart_currency_code: "BRL",
  channel: null,
  channel_currency_code: "BRL",
  checkout_method: null,
  contact_phone: null,
  conversion_time: null,
  coupon_code: null,
  cpf: '',
  corporate_name: '',
  state_registration: '',
  created_at: "",
  customer_email: '',
  customer_first_name: '',
  customer_last_name: '',
  discount: "",
  exchange_rate: null,
  formated_base_discount: "",
  formated_base_grand_total: "",
  formated_base_sub_total: "",
  formated_base_tax_total: "",
  formated_discount: "",
  formated_grand_total: "",
  formated_sub_total: "",
  formated_tax_total: "",
  global_currency_code: "BRL",
  grand_total: "0",
  id: 1,
  is_active: 1,
  is_gift: 0,
  is_guest: 1,
  items: [],
  items_count: 2,
  items_qty: "",
  payment: null,
  payment_token: "",
  selected_shipping_rate: {
    base_price: 0,
    carrier: "",
    carrier_title: "",
    created_at: "",
    formated_base_price: "",
    formated_price: "",
    id: null,
    method: null,
    method_description: null,
    method_title: null,
    price: 0,
    updated_at: null,
  },
  shipping_address: null,
  shipping_method: null,
  sub_total: "0",
  tax_total: "",
  updated_at: ""
};

const cartDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case CART_DATA:
      return action.payload
    default:
      return state;
  }
}

export default cartDataReducer;
