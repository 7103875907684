import axios from 'axios';

const api = axios.create({
  // baseURL: 'http://api-local.cartbuy.store:8085/api/'
  baseURL: 'https://api.cartbuy.io/api/'
  //baseURL: 'https://apiqa.flitshop.com.br/api/'
  //baseURL: 'http://dev.api.flitcommerce.com/api/'
})

export default api;
