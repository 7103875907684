import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../api/api';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { cartData } from '../../store/actions/cartDataAction';
import Body from './components/Body';

function Checkout({ cartData, data }) {

  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    ReactPixel.fbq('track', 'InitiateCheckout');
  }, [])

  useEffect(() => {
    if (params.payment_token) {
      api('/checkout/cart', { params: { payment_token: params.payment_token }, headers: { Authorization: `Bearer ${localStorage.getItem('tokenApi')}` } }).then(response => {
        if (response.data.data !== null) {
          cartData(response.data.data);
        } else {
          history.replace(`/checkout/confirmation/${params.payment_token}`)
        }
      }).catch(() => console.log('Não foi possível carregar os produtos'))
    } else {
      api('/checkout/cart', { params: { payment_token: localStorage.getItem('paymentToken') } }).then(response => {
        if (response.data.data !== null) {
          cartData(response.data.data);
        } else {
          window.location.href = '/'
        }
      }).catch(() => console.log('Não foi possível carregar os produtos'))
    }
  }, [cartData, history, params.payment_token])

  return (
    <div>
      <Header />
      <hr
        style={{
          margin: "0px"
        }}
      />
      <Body />
      <Footer  data={data}/>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    data: state.cartData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    cartData: data => {
      return dispatch(cartData(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
