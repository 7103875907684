import axios from 'axios'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Accordion, Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import { FaLock } from 'react-icons/fa'
import InputMask from 'react-input-mask'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import api from '../../../../api/api'
import CardProducts from '../../../../components/CardProducts'
import CreditCard from '../../../../components/CreditCard'
import { cartData as responseCartData } from '../../../../store/actions/cartDataAction'
import { initialState as initialCartState } from '../../../../store/reducers/cartDataReducer'
import { orderData } from '../../../../store/actions/orderAction'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga'
import { debounce } from 'lodash'
import { validateCPF, validateCNPJ, validatePhone, validateEmail } from '../../../../utils/validations'

const Body = ({ cartData, orderData, responseCartData, config }) => {
  const background = config?.json_settings?.general?.checkout?.body?.background_color
  const background_button = config?.json_settings?.general?.checkout?.buttons?.background
  const color_text = config?.json_settings?.general?.checkout?.buttons?.text
  const [cep, setCep] = useState(cartData && cartData.shipping_address ? cartData.shipping_address.postcode : '')
  const [numero, setNumero] = useState(cartData?.shipping_address?.number)
  const [complemento, setComplemento] = useState('')
  const [countries, setCountries] = useState([])
  const [countryStates, setCountryStates] = useState(null)
  const [cStateTxtVisible, setCStateTxtVisible] = useState(true)
  const [addressFull, setAddressFull] = useState({
    uf: '',
    country: 'BR'
  })
  const [loading, setLoading] = useState(false)
  const [loadingOrder, setLoadingOrder] = useState(false)
  const [showFields, setShowFields] = useState(true)
  const [email, setEmail] = useState(cartData && cartData.customer_email ? cartData.customer_email : '')
  const [name, setName] = useState(cartData && cartData.customer_first_name ? cartData.customer_first_name : '')
  const [surname, setSurName] = useState(cartData && cartData.customer_last_name ? cartData.customer_last_name : '')
  const [phone, setPhone] = useState(cartData && cartData.contact_phone ? cartData.contact_phone : '55')
  const [cpf, setCpf] = useState(cartData && cartData.cpf ? cartData.cpf : '')
  const [cpfValid, setCpfValid] = useState(true)
  const [phoneValid, setPhoneValid] = useState(true)
  const [emailValid, setEmailValid] = useState(true)

  const [state_registration, setStateRegistration] = useState(cartData && cartData.state_registration ? cartData.state_registration : '')
  const [corporate_name, setCorporateName] = useState(cartData && cartData.corporate_name ? cartData.corporate_name : '')
  const [observations, setObservations] = useState('')
  const [shippingMethods, setShippingMethods] = useState(cartData?.shipping_rates || [])
  const [contactName, setContactName] = useState('')
  const [paymentData, setPaymentData] = useState({ methods: [] })
  const [cardObject, setCardObject] = useState({})
  const [option, setOption] = useState('pix')
  const [publicKey, setPublicKey] = useState('')
  const [installment, setInstallment] = useState(1)
  const [selectedOption, setSelectedOption] = useState(true)
  const [paymentMethod, setPaymentMethod] = useState('')
  const [selectBank, setSelectBank] = useState('ITAU')
  const [birthDate, setBirthDate] = useState('')

  const inputNumberRef = useRef()
  const name_ref = useRef()
  const surname_ref = useRef()
  const address_logradouro_ref = useRef()
  const address_localidade_ref = useRef()
  const address_bairro_ref = useRef()
  const address_uf_ref = useRef()
  const address_country_ref = useRef()
  const complemento_ref = useRef()
  const email_ref = useRef()
  const cep_ref = useRef()
  const contactName_ref = useRef()
  const corporate_name_ref = useRef()
  const state_registration_ref = useRef()
  const cpf_ref = useRef()
  const phone_ref = useRef()

  let handleChangeCep

  // variables translations
  const langEn = (config?.json_settings?.general?.language === 'en')
  const pickOneTranslation = langEn ? 'Select one' : 'Selecione um';
  const personalInfo = langEn ? 'Personal Info' : 'Informações pessoais';
  const subtitleTranslation = langEn ? 'Who is the receiver?' : 'Para quem devemos entregar o pedido?';
  const haveAnAccount = langEn ? 'Do you have an account?' : 'Já possui uma conta?';
  const loginTranslation = langEn ? 'Login?' : 'Fazer login?';
  const nameTranslation = langEn ? 'Name*' : 'Nome*';
  const lastNameTranslation = langEn ? 'Last Name*' : 'Sobrenome*';
  const phoneTranslation = langEn ? 'Phone number*' : 'Telefone / Celular*';
  const additionalInformation = langEn ? 'Aditional Information' : 'Informações adicionais';
  const obsInformation = langEn ? 'Observations' : 'Observações';
  const finishOrderTranslation = langEn ? 'Finish Order' : 'Finalizar Compra';
  const shippingInfo = langEn ? 'Shipping Information' : 'Informações de entrega';
  const zipMask = langEn ? '999999999' : '99999-999';
  const zipTranslation = langEn ? 'Zip Code*' : 'CEP*';
  const stateTranslation = langEn ? 'State*' : 'UF*';
  const shippingInfoSubtitle = langEn ? 'Where is the shipping destination?' : 'Para onde devemos entregar o pedido?';
  const invalidMailTranslation = langEn ? 'Invalid e-mail' : 'E-mail inválido';
  const invalidPhoneTranslation = langEn ? 'Invalid phone number' : 'Telefone / Celular inválido';
  const invalidDocTranslation = langEn ? 'Document Invalid' : 'CPF / CNPJ inválido';
  const shippingMethodsTranslation = langEn ? 'Shipping Methods' : 'Formas de Entrega';
  const shippingMethodsSubtitle = langEn ? 'Choose how you want to receive your order' : 'Escolha como receber o seu pedido';
  const paymentMethodTranslation = langEn ? 'Payment method' : 'Método de pagamento';
  const paymentMethodSubtitle = langEn ? 'Choose the payment method below:' : 'Escolha o seu método de pagamento abaixo:';

  const fields_details = {
    phone: {
      placeholder: config?.json_settings?.checkout?.fields?.phone?.placeholder ?? phoneTranslation
    }
  }

  const history = useHistory();

  const params = useParams();

  const { addToast } = useToasts();

  const formatedPrice = (price) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price)
  }

  useEffect(() => {
    if (params.payment_token) {
      history.replace(`/checkout/${params.payment_token}`)
    } else if (cartData === null && localStorage.getItem('paymentToken') && localStorage.getItem('paymentToken').includes('null')) {
      history.replace(`/checkout/confirmation/${localStorage.getItem('paymentToken')}`)
    }
  }, [params.payment_token, cartData, history]);

  useEffect(() => {
    if(cep.length === 9){
      async function getCepInfo(){
        const url = `https://viacep.com.br/ws/${cep}/json/`
        const response = await fetch(url);
        const data = await response.json();
        setAddressFull({
          ...addressFull,
          bairro: data.bairro ?? '',
          logradouro: data.logradouro ?? '',
          localidade: data.localidade ?? '',
          uf: data.uf ?? ''
        })
      }
      getCepInfo()
    }
  }, [cep])

  useEffect(() => {
    if (!email && cartData.customer_email) {
      setEmail(cartData?.customer_email || '');
      setName(cartData?.customer_first_name || '');
      setSurName(cartData?.customer_last_name || '');
      setPhone(cartData?.contact_phone || '');
      setCpf(cartData?.cpf || '');
      setStateRegistration(cartData?.state_registration);
      setCorporateName(cartData?.corporate_name);
      setNumero(cartData?.shipping_address?.number || '');
      setComplemento(cartData?.shipping_address?.address2 || '');
      if (cartData.shipping_address && (cartData.shipping_address.first_name || cartData.shipping_address.last_name)) {
        setContactName(`${cartData?.shipping_address?.first_name} ${cartData?.shipping_address?.last_name}`);
      } else {
        setContactName('');
      }
    }

    /*
    setAddressFull({
      bairro: cartData?.shipping_address?.neighborhood,
      // cep: cartData?.shipping_address?.postcode,
      complemento: cartData?.shipping_address?.address2,
      localidade: cartData?.shipping_address?.city,
      logradouro: cartData?.shipping_address?.address1,
      uf: cartData?.shipping_address?.state,
    })
    */
  }, [cartData, handleChangeCep, email]);

  useEffect(() => {
    if (countries.length === 0 || !addressFull.country) {
      return
    }
    setCountryStates(null)
    const country = countries.find(({ code }) => code === addressFull.country)
    if (!country) {
      return
    }
    api(`/countries/${country.id}/states`).then(({ data }) => {
      const { data: allStates } = data
      setCStateTxtVisible(allStates.length === 0)
      const mappedStates = allStates.map((state) => {
        return (
          <option key={state.id} value={state.code}>{state.default_name}</option>
        )
      })
      setCountryStates(mappedStates)
    })
  }, [addressFull.country, countries])

  useEffect(() => {
    api('/countries?pagination=0').then(({ data }) => {
      const { data: allCountries } = data
      allCountries.sort((a, b) => {
        return a.name < b.name ? -1 : 1
      })
      setCountries(allCountries)
    })

    if (localStorage.getItem('tokenApi')) {
      api('/customer/get', { headers: { Authorization: `Bearer ${localStorage.getItem('tokenApi')}` } }).then(responseUserData => {
        const response = responseUserData.data.data
        setName(response.first_name)
        setSurName(response.last_name)
        setEmail(response.email)
        setPhone(response.phone)
        setCpf(response.cpf)
      })
    }
  }, [])

  useEffect(() => {
    if (config.id && config?.google_analytics_tracking_id) {
      ReactGA.plugin.require('ec')
      ReactGA.plugin.execute('ec', 'setAction', 'checkout', { step: 1 })
      ReactGA.send('pageview')
    }
  }, [config])

  handleChangeCep = (typedCep) => {
    // if (typedCep && typedCep.length >= 9) {
    //   setLoading(true)
    //   axios.get(`https://viacep.com.br/ws/${typedCep}/json`).then(response => {
    //     handleChangeAddress(response.data, setAddressFull)
    //     handleChangeAddress(response.data.complemento || '', setComplemento)
    //     if (typedCep !== cartData?.shipping_address?.postcode) {
    //       handleChangeAddress('', setNumero)
    //     }
    //     inputNumberRef.current.focus()
    //   }).catch(e => {
    //     addToast('CEP não encontrado em nossa base de dados. Por favor, preencha os dados manualmente.', { appearance: 'error', autoDismiss: true })
    //   }).finally(() => setLoading(false))
    // } else {
    //   setAddressFull({})
    // }
    handleChangeAddress(typedCep, setCep)
  }

  const cleanString = (input) => {
    let output = '';
    for (let i = 0; i < input.length; i++) {
      const a = input.charCodeAt(i) <= 127
      const b = input.charCodeAt(i) >= 160
      const c = input.charCodeAt(i) <= 255
      if (a || (b && c)) {
        output += input.charAt(i);
      }
    }
    return output
  }

  const handleChangeAddress = (value, callback) => {
    callback(value, postSaveAddress({
      name: name_ref.current ? name_ref.current.value : name || '',
      surname: surname_ref.current ? surname_ref.current.value : surname || '',
      addressFull: {
        logradouro: address_logradouro_ref.current ? address_logradouro_ref.current.value : addressFull.logradouro || '',
        localidade: address_localidade_ref.current ? address_localidade_ref.current.value : addressFull.localidade || '',
        bairro: address_bairro_ref.current ? address_bairro_ref.current.value : addressFull.bairro || '',
        uf: address_uf_ref.current ? address_uf_ref.current.value : addressFull.uf || '',
        country: address_country_ref.current ? address_country_ref.current.value : addressFull.country || '',
      },
      complemento: complemento_ref.current ? complemento_ref.current.value : complemento || '',
      numero: inputNumberRef.current ? inputNumberRef.current.value : numero || '',
      email: email_ref.current ? email_ref.current.value : email || '',
      cep: cep_ref.current ? cep_ref.current.value : cep || '',
      contactName: contactName_ref.current ? contactName_ref.current.value : contactName || '',
      corporate_name: corporate_name_ref.current ? corporate_name_ref.current.value : corporate_name || '',
      state_registration: state_registration_ref.current ? state_registration_ref.current.value : state_registration || '',
      cpf: cpf_ref.current ? cpf_ref.current.value : cpf || '',
      phone: phone_ref.current ? phone_ref.current.value : phone || '',
      selected_shipping_rate_method: cartData.selected_shipping_rate ? cartData.selected_shipping_rate.method : null,
      validated: validateFields(false)
    }));
  }

  const toastOrderError = (error) => {
    if (error.response?.data?.error) {
      addToast(error.response.data.error, { appearance: 'error', autoDismiss: true })
    } else if (error.response?.data?.errors) {
      Object.values(error.response.data.errors).forEach(message => {
        addToast(message, { appearance: 'error', autoDismiss: true })
      })
    } else {
      addToast('Algo deu errado. Por favor, revise as informações e tente novamente.', { appearance: 'error', autoDismiss: true })
    }

    setLoadingOrder(false)

    throw error
  }

  const postSaveAddress = useRef(debounce(payload => {
    if (payload.validated) {
      setLoading(true)
      api.post('/checkout/save-address', {
        billing: {
          first_name: payload.name,
          last_name: payload.surname,
          address1: payload.addressFull.logradouro,
          address2: payload.complemento,
          number: payload.numero,
          neighborhood: payload.addressFull.bairro,
          phone: payload.phone,
          email: payload.email,
          city: payload.addressFull.localidade,
          state: payload.addressFull.uf,
          postcode: payload.cep,
          country: payload.addressFull.country,
          contact_name: payload.contactName_ref
        },
        shipping: {
          first_name: payload.name,
          last_name: payload.surname,
          address1: payload.addressFull.logradouro,
          address2: payload.complemento,
          number: payload.numero,
          neighborhood: payload.addressFull.bairro,
          phone: payload.phone,
          email: payload.email,
          city: payload.addressFull.localidade,
          state: payload.addressFull.uf,
          postcode: payload.cep,
          country: payload.addressFull.country,
          contact_name: payload.contactName
        },
        corporate_name: payload.corporate_name,
        state_registration: payload.state_registration,
        cpf: payload.cpf,
        contact_phone: payload.phone,
      }, {
        params: {
          payment_token: params.payment_token ? params.payment_token : localStorage.getItem('paymentToken')
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tokenApi')}`
        }
      }).then(response => {
        setShippingMethods(response.data.data, handleSaveShipping(payload.selected_shipping_rate_method))
        if (config.google_analytics_tracking_id) {
          ReactGA.plugin.require('ec')
          ReactGA.plugin.execute('ec', 'setAction', 'checkout', { step: 2 })
          ReactGA.send('pageview')
        }
      }).catch(() => {
        addToast('Erro ao calcular as taxas das formas de entrega.', { appearance: 'error', autoDismiss: true })
      }).finally(() => {
        setLoading(false)
      });
    }
  }, 850)).current;

  function paymentMercadoPago() {
    setLoadingOrder(true)
    checkoutSaveOrder().then(response => {
      const redirect_url = response.data.redirect_url;

      window.Mercadopago.setPublishableKey(publicKey);
      let bin = '';
      if (option === 'credit_card' && cardObject && window) {
        bin = cardObject.cardNumber.replace(/\D/g, '').substring(0, 6);

        if (!window.Mercadopago || !window.Mercadopago.getPaymentMethod) {
          return;
        }

        window.Mercadopago.getPaymentMethod({ bin }, (status, response) => {
          if (status !== 200 && status !== 201 && status !== 203) {
            window.Mercadopago.clearSession();
            addToast(`Verifique suas informações, pois encontramos um erro ao enviar para pagamento`, { appearance: 'error', autoDismiss: true });
            setLoadingOrder(false);
            return false;
          }
          let paymentMethodId = response[0].id;

          const form = document.createElement('form');
          const cardNumber = document.createElement('input');
          cardNumber.setAttribute('data-checkout', 'cardNumber');
          cardNumber.setAttribute('value', cardObject.cardNumber && cardObject.cardNumber.replace(/\D/g, ''));
          form.appendChild(cardNumber);

          const cardholderName = document.createElement('input');
          cardholderName.setAttribute('data-checkout', 'cardholderName');
          cardholderName.setAttribute('value', cardObject.cardHolder);
          form.appendChild(cardholderName);

          const cardExpirationMonth = document.createElement('input');
          cardExpirationMonth.setAttribute('data-checkout', 'cardExpirationMonth');
          cardExpirationMonth.setAttribute('value', parseInt(cardObject.cardMonth));
          form.appendChild(cardExpirationMonth);

          const cardExpirationYear = document.createElement('input');
          cardExpirationYear.setAttribute('data-checkout', 'cardExpirationYear');
          cardExpirationYear.setAttribute('value', cardObject.cardYear.slice(-2));
          form.appendChild(cardExpirationYear);

          const securityCode = document.createElement('input');
          securityCode.setAttribute('data-checkout', 'securityCode');
          securityCode.setAttribute('value', cardObject.cardCvv);
          form.appendChild(securityCode);

          const docType = document.createElement('input');
          docType.setAttribute('data-checkout', 'docType');
          docType.setAttribute('value', 'CPF');
          form.appendChild(docType);

          const docNumber = document.createElement('input');
          docNumber.setAttribute('data-checkout', 'docNumber');
          docNumber.setAttribute('value', cardObject.cardCPF && cardObject.cardCPF.replace(/\D/g, ''));
          form.appendChild(docNumber);

          window.Mercadopago.createToken(form, (status, response) => {
            if (status !== 200 && status !== 201) {
              window.Mercadopago.clearSession();
              addToast('Verifique suas informações do cartão, pois encontramos um erro ao enviar para pagamento', { appearance: 'error', autoDismiss: true });
              setLoadingOrder(false);
              return;
            }

            const payment_data = {
              payment_method_id: paymentMethodId,
              payment_type_id: option,
              installments: typeof installment === 'number' ? installment : parseInt(installment.split('x')[0]),
              docNumber: cardObject.cardCPF.replace(/\D/g, ''),
              token: response.id
            };

            submitOrderFinal(redirect_url, payment_data).then(response_payment => {
              const order = response_payment.data.data;
              orderData(order)
              redirectToConfirmation('mercado_pago', 'credit', order.id);
            }).catch(() => {
              window.Mercadopago.clearSession();
            })
          })
        })

      } else if (option === 'ticket') {
        if (!email) {
          addToast('Email vazio / inválido', { appearance: 'error', autoDismiss: true });
          setLoadingOrder(false);
          return;
        }

        if (!name) {
          addToast('Nome vazio / inválido', { appearance: 'error', autoDismiss: true });
          setLoadingOrder(false);
          return;
        }

        if (!cpf) {
          addToast('CPF vazio / inválido', { appearance: 'error', autoDismiss: true });
          setLoadingOrder(false);
          return;
        }

        const payment_data = {
          payment_method_id: "bolbradesco",
          payment_type_id: option,
          docNumber: cpf.replace(/\D/g, ''),
        };

        submitOrderFinal(redirect_url, payment_data).then(response_payment => {
          const order = response_payment.data.data;
          orderData(order)
          redirectToConfirmation('mercado_pago', 'billet', order.id);
        })
      }
    })
  }

  const handleSavePayment = (payment_method) => {
    setLoading(true)
    api.post('checkout/save-payment', {
      payment: {
        method: payment_method
      }
    }, {
      params: {
        payment_token: params.payment_token ? params.payment_token : localStorage.getItem('paymentToken')
      }
    }).then((response) => {
      responseCartData(response.data.data.cart)
      if (config.google_analytics_tracking_id) {
        ReactGA.plugin.require('ec')
        ReactGA.plugin.execute('ec', 'setAction', 'checkout', { step: 4 })
        ReactGA.send('pageview')

        ReactGA.plugin.execute('ec', 'setAction', 'checkout_option', {
          step: 4,
          option
        })
        ReactGA.event({
          category: 'Checkout',
          action: 'Option',
          label: 'Forma de Pagamento'
        })
      }
    }).catch(() => {
      addToast('Erro ao cadastrar forma de pagamento, tente novamente!', { appearance: 'error', autoDismiss: true })
    }).finally(() => {
      setLoading(false)
    });
  }

  const tokenizePagSeguro = (session_id) => {
    const psegparams = [
      `sessionId=${session_id}`,
      `cardNumber=${cardObject.cardNumber.replace(/\D/g, '')}`,
      `cardBrand=${cardObject.cardBrand}`,
      `cardCvv=${cardObject.cardCvv}`,
      `cardExpirationMonth=${cardObject.cardMonth}`,
      `cardExpirationYear=${cardObject.cardYear}`,
    ]

    const headers = {
      'Accept': 'application/xml; charset=UTF-8',
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    const uolUri = 'https://df.uol.com.br/v2/cards'

    return axios.post(uolUri, psegparams.join('&'), { headers }).catch(() => {
      addToast('Verifique os dados do cartão e tente novamente.', { appearance: 'error', autoDismiss: true })
      setLoadingOrder(false)
    })
  }

  const redirectToConfirmation = (methodCode, methodType, orderId) => {
    const pt = params.payment_token ? params.payment_token : localStorage.getItem('paymentToken');
    const usedMethod = paymentData.methods.find(method => method.method === methodCode);
    const redirectUrlKey = `${methodType}_redirect_url`;

    responseCartData(initialCartState);
    localStorage.removeItem('paymentToken');
    localStorage.removeItem('ref');
    localStorage.removeItem('utm_source');
    localStorage.removeItem('utm_content');
    localStorage.removeItem('utm_medium');
    localStorage.removeItem('utm_campaign');

    if (methodCode && methodType && usedMethod?.app?.config[redirectUrlKey]) {
      window.location.replace(`${usedMethod.app.config[redirectUrlKey]}?order_id=${orderId}`);
    } else {
      window.location.replace(`/checkout/confirmation/${pt}`);
    }
  }

  const checkoutSaveOrder = () => {
    const additionalData = {
      additional_informations: observations,
    }

    return api.post('checkout/save-order', additionalData, {
      params: {
        payment_token: params.payment_token ? params.payment_token : localStorage.getItem('paymentToken')
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('tokenApi')}`
      }
    }).catch(toastOrderError)
  }

  const submitOrderFinal = async (redirect_url, data) => {
    return axios.post(redirect_url, data, {
      params: {
        payment_token: params.payment_token ? params.payment_token : localStorage.getItem('paymentToken'),
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('tokenApi')}`
      }
    }).then(payment_response => {
      const order = payment_response.data.data

      if (config?.facebook_pixel_number) {
        const fbPurchase = {
          value: cartData.sub_total,
          currency: 'BRL',
          contents: cartData.items.map(item => ({
            id: item.child ? item.child.product.id : item.product.id,
            name: item.child ? item.child.name : item.name,
            quantity: item.quantity
          })),
          content_type: 'product'
        }

        ReactPixel.fbq('track', 'Purchase', fbPurchase)

        ReactPixel.fbq('track', 'AddPaymentInfo', {
          ...fbPurchase,
          content_ids: option,
          content_category: 'snippets',
          content_type: 'product_group'
        })

        if (option === 'credit' || option === 'credit_card') {
          ReactPixel.fbq('trackCustom', 'Purchase CC', fbPurchase)
        }

        if (option === 'ticket' || option === 'boleto') {
          ReactPixel.fbq('trackCustom', 'Purchase Boleto', fbPurchase)
        }
      }

      if (config?.google_analytics_tracking_id) {
        ReactGA.plugin.require('ec')

        cartData.items.forEach((item) => {
          ReactGA.plugin.execute('ec', 'addProduct', {
            // id: order.id,
            name: item.child ? item.child.name : item.name,
            sku: item.child ? item.child.sku : item.sku,
            // category: 'categoria',
            price: item.price,
            quantity: item.quantity,
            currency: 'BRL',
          });
        })

        if (cartData.applied_cart_rules) {
          cartData.applied_cart_rules.forEach((cart_rule) => {
            ReactGA.plugin.execute('ec', 'addPromo', {
              name: cart_rule.name,
            })
          })
        }

        ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
          id: order.id,
          // affiliation: config?.owner_name,
          revenue: order.grand_total,
          shipping: order.shipping_amount,
          // discount: order.discount_amount,
          tax: 0,
          currency: 'BRL',
          coupon: order.coupon_code
        })

        ReactGA.send('pageview')
        ReactGA.plugin.execute('ec', 'clear')
      }

      return payment_response
    }).catch(toastOrderError)
  }

  const paymentPagSeguro = () => {
    setLoadingOrder(true)
    checkoutSaveOrder().then(response => {
      setLoadingOrder(true)

      window.PagSeguroDirectPayment.onSenderHashReady(hash_response => {
        if (option === 'credit_card') {
          tokenizePagSeguro(response.data.session_id).then(responsePagSeguro => {
            const result = require('xml-js').xml2js(responsePagSeguro.data, { compact: true, ignoreComment: true, spaces: 2 });

            if (!result.card || !result.card.token || !result.card.token._text) {
              addToast('Por favor, verifique os dados do cartão e tente novamente.', { appearance: 'error', autoDismiss: true })
              setLoadingOrder(false)
              return false
            }

            const token = result.card.token._text;

            const localOrderData = {
              token,
              docNumber: cpf,
              installments: installment,
              payment_type_id: option,
              bearer_name: cardObject.cardHolder,
              sender_hash: hash_response.senderHash,
              birth_date: birthDate
            }

            submitOrderFinal(response.data.redirect_url, localOrderData).then((response) => {
              addToast('Obrigado por comprar conosco!', { appearance: 'success', autoDismiss: true });
              redirectToConfirmation('pagseguro', 'credit', response.data.data.id);
            })
          })
        } else if (option === 'online_debit') {
          const localOrderData = {
            bank_name: selectBank,
            docNumber: cpf,
            installments: installment,
            payment_type_id: option,
            sender_hash: hash_response.senderHash
          }
          submitOrderFinal(response.data.redirect_url, localOrderData).then((response) => {
            addToast('Obrigado por comprar conosco!', { appearance: 'success', autoDismiss: true })
            redirectToConfirmation('pagseguro', 'debit', response.data.data.id);
          })
        } else if (option === 'ticket') {
          const localOrderData = {
            docNumber: cpf,
            installments: installment,
            payment_type_id: option,
            sender_hash: hash_response.senderHash
          }
          submitOrderFinal(response.data.redirect_url, localOrderData).then((response) => {
            addToast('Obrigado por comprar conosco!', { appearance: 'success', autoDismiss: true })
            redirectToConfirmation('pagseguro', 'billet', response.data.data.id);
          })
        } else {
          addToast('Por favor, selecione uma forma de pagamento e tente novamente.', { appearance: 'error', autoDismiss: true })
        }
      });

    })
  }

  const handleSaveShipping = (method) => {
    if (loading) {
      return false
    }
    if (method) {
      setLoading(true)
      api.post('checkout/save-shipping', {
        shipping_method: method
      }, {
        params: {
          payment_token: params.payment_token ? params.payment_token : localStorage.getItem('paymentToken')
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tokenApi')}`
        }
      }).then(response => {
        setPaymentData(response.data.data)
        responseCartData(response.data.data.cart)
        if (config.google_analytics_tracking_id) {
          ReactGA.plugin.require('ec')
          ReactGA.plugin.execute('ec', 'setAction', 'checkout', { step: 3 })
          ReactGA.send('pageview')

          ReactGA.plugin.execute('ec', 'setAction', 'checkout_option', {
            step: 3,
            option: method
          })
          ReactGA.event({
            category: 'Checkout',
            action: 'Option',
            label: 'Forma de Entrega'
          })
        }
      }).catch(() => {
        addToast('Erro ao cadastrar o método de entrega', { appearance: 'error', autoDismiss: true }
        )
      }).finally(() => {
        setLoading(false)
      });
    }
  }

  const saveOrder = () => {
    setLoadingOrder(true)
    checkoutSaveOrder().then((response) => {
      if (response?.data?.redirect_url) {
        const url = new URL(response.data.redirect_url)
        const pt = params.payment_token ? params.payment_token : localStorage.getItem('paymentToken')
        url.searchParams.set('payment_token', pt)
        url.searchParams.set('domain', window.location.host)
        const redirectUrl = url.toString()
        window.location.replace(redirectUrl);
        return
      }
      addToast('Obrigado por efetuar a compra conosco', { appearance: 'success', autoDismiss: true })
      redirectToConfirmation(false, false, false);
    }).finally(() => setLoadingOrder(false))
  }

  function createCardToken() {
    const posting_card = {
      holder_name: cardObject.cardHolder,
      card_number: cardObject.cardNumber.replace(/\D/g, ''),
      expiration_month: cardObject.cardMonth,
      expiration_year: cardObject.cardYear,
      security_code: cardObject.cardCvv
    }

    return axios({
      url: `https://api.zoop.ws/v1/marketplaces/${process.env.REACT_APP_ZOOP_MARKETPLACE_ID}/cards/tokens`,
      method: 'post',
      data: posting_card,
      crossDomain: true,
      auth: {
        username: process.env.REACT_APP_ZOOP_ZPK_KEY,
        password: ''
      }
    })
  }

  const paymentFlitPay = () => {
    setLoadingOrder(true);
    checkoutSaveOrder().then(response => {
      const redirect_url = response.data.redirect_url;
      if (option === 'ticket') {
        submitOrderFinal(redirect_url, { payment_type: 'boleto' }).then(response => {
          redirectToConfirmation('flitpay', 'billet', response.data.data.id);
        })
        return true
      }
      createCardToken().then(response => {
        const localOrderData = {
          payment_type: 'credit',
          installments: installment,
          token: response.data.id
        }
        submitOrderFinal(redirect_url, localOrderData).then(response => {
          redirectToConfirmation('flitpay', 'credit', response.data.data.id);
        }).finally(() => setLoadingOrder(false))
      }).catch(error => {
        console.log('erro cartao', error)
        addToast('Por favor, verifique os dados do cartão.', { appearance: 'error', autoDismiss: true })
        setLoadingOrder(false)
      });
    })
  }

  const validateFields = (should_toast = true) => {
    let message = '', passes = true;

    const name_v = name_ref.current ? name_ref.current.value : name || '';
    const surname_v = surname_ref.current ? surname_ref.current.value : surname || '';
    const addressFull_v = {
      logradouro: address_logradouro_ref.current ? address_logradouro_ref.current.value : addressFull.logradouro || '',
      localidade: address_localidade_ref.current ? address_localidade_ref.current.value : addressFull.localidade || '',
      bairro: address_bairro_ref.current ? address_bairro_ref.current.value : addressFull.bairro || '',
      uf: address_uf_ref.current ? address_uf_ref.current.value : addressFull.uf || ''
    };
    const numero_v = inputNumberRef.current ? inputNumberRef.current.value : numero || '';
    const email_v = email_ref.current ? email_ref.current.value : email || '';
    const cep_v = cep_ref.current ? cep_ref.current.value : cep || '';
    const corporate_name_v = corporate_name_ref.current ? corporate_name_ref.current.value : corporate_name || '';
    const cpf_v = cpf_ref.current ? cpf_ref.current.value : cpf || '';
    const phone_v = phone_ref.current ? phone_ref.current.value : phone || '';

    if (email_v === '' || !validateEmail(email)) {
      message = 'Preencha o campo de e-mail com um e-mail válido';
      passes = false;
    } else if (numero_v === '') {
      message = 'Preencha o campo de número do endereço';
      passes = false;
    } else if (name_v === '') {
      message = 'Preencha o campo nome';
      passes = false;
    } else if (surname_v === '') {
      message = 'Preencha o campo sobrenome';
      passes = false;
    } else if (addressFull_v.logradouro === '') {
      message = 'Preencha o campo endereço';
      passes = false;
    } else if (addressFull_v.bairro === '') {
      message = 'Preencha o campo bairro';
      passes = false;
    } else if (addressFull_v.localidade === '') {
      message = 'Preencha o campo cidade';
      passes = false;
    } else if (addressFull_v.uf === '') {
      message = 'Preencha o campo estado';
      passes = false;
    } else if (cep_v === '' || cep_v.length < 8) {
      message = 'Preencha o campo CEP com um CEP válido';
      passes = false;
    } else if (cpf_v === '' || (!validateCPF(cpf_v) && !validateCNPJ(cpf_v))) {
      message = 'Preencha o campo CPF/CNPJ com um CPF/CNPJ válido';
      passes = false;
    } else if (!validatePhone(phone_v)) {
      message = `Preencha o campo ${fields_details.phone.placeholder} com um número válido`;
      passes = false;
    } else if (cpf_v.replace(/\D/g, '').length > 11 && corporate_name_v === '') {
      message = 'Preencha o campo razão social';
      passes = false;
    }
    if (!passes) {
      if (should_toast) {
        addToast(message, { appearance: 'error', autoDismiss: true });
      }
      return false;
    }
    return true;
  }

  const handleSaveOrder = () => {
    if (!validateFields()) {
      return;
    }

    if (!cartData || !cartData.selected_shipping_rate) {
      return addToast('Selecione uma forma de entrega', { appearance: 'error', autoDismiss: true });
    } else if (paymentMethod === '') {
      return addToast('Selecione uma forma de pagamento', { appearance: 'error', autoDismiss: true });
    }

    if (option === 'credit_card') {
      if (
        cardObject &&
        (!cardObject.cardNumber || cardObject.cardNumber.length < 14) &&
        (!cardObject.cardHolder || cardObject.cardHolder.length < 2) &&
        (!cardObject.cardMonth || cardObject.cardMonth.length < 2) &&
        (!cardObject.cardYear || cardObject.cardYear.length < 2) &&
        (!cardObject.cardCvv || cardObject.cardCvv.length < 2)
      ) {
        return addToast('Preencha todos os dados do cartão', { appearance: 'error', autoDismiss: true });
      }
      switch (paymentMethod) {
        case 'mercado_pago': paymentMercadoPago(); break;
        case 'pagseguro': paymentPagSeguro(); break;
        case 'flit_pay': paymentFlitPay(); break;
        default: return false;
      }
    } else {
      switch (paymentMethod) {
        case 'mercado_pago': paymentMercadoPago(); break;
        case 'flit_pay': paymentFlitPay(); break;
        case 'pagseguro': paymentPagSeguro(); break;
        case 'moneytransfer': saveOrder(); break;
        case 'cashondelivery': saveOrder(); break;
        case 'paypal_standard': saveOrder(); break;
        default: return false;
      }
    }
  }

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        backgroundColor: background
      }}
    >
      <Container>
        <Row className="flex-row-reverse">
          <Col sm={12} lg={5}>
            <CardProducts
              config={config}
              data={cartData || [{ items: [] }]}
              recalculateShipping={() => {
                handleChangeAddress(cep, setCep);
              }}
            />
          </Col>
          <Col sm={12} lg={7} className="d-flex justify-content-start flex-column mb-3">
            <Row>
              <Col lg={11}>
                <section className="mt-5">
                  <div className="title-section d-flex justify-content-between flex-mobile-without-center">
                    <div>
                      <h3 className="title-checkout">{personalInfo}</h3>
                      <small>{subtitleTranslation}</small>
                    </div>
                    {localStorage.getItem('tokenApi') ? '' :
                      <div className="d-flex text-right text-mobile-left">
                        <p className="mt-3 mb-0" style={{ fontSize: "14px" }}>
                          {haveAnAccount}
                        </p>
                        <a
                          href="/login?redirect_url=/checkout"
                          className="p-1 mt-1 btn btn-link"
                        >
                          {loginTranslation}
                        </a>
                      </div>
                    }
                  </div>
                  <Form className="mt-1 pt-3">
                    <Form.Control
                      ref={email_ref}
                      value={email}
                      onChange={event => {
                        if (!validateEmail(event.target.value)) {
                          setEmailValid(false)
                          setEmail(event.target.value)
                        } else {
                          setEmailValid(true)
                          handleChangeAddress(cleanString(event.target.value), setEmail)
                        }
                      }}
                      type="email"
                      placeholder="Email*"
                    />
                    {!emailValid && <div className="bg-danger font-14 text-center text-white">{invalidMailTranslation}</div>}
                    <Row>
                      <Col md={6}>
                        <Form.Control
                          ref={name_ref}
                          value={name}
                          onChange={event => handleChangeAddress(cleanString(event.target.value), setName)}
                          className="mt-2"
                          placeholder={nameTranslation}
                        />
                      </Col>
                      <Col md={6}>
                        <Form.Control
                          ref={surname_ref}
                          value={surname}
                          onChange={event => handleChangeAddress(cleanString(event.target.value), setSurName)}
                          className="mt-2"
                          placeholder={lastNameTranslation}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <InputMask
                          ref={phone_ref}
                          mask="+9999999999999"
                          maskChar={null}
                          value={phone}
                          className="form-control mt-2"
                          onChange={event => {
                            if (!validatePhone(event.target.value)) {
                              setPhoneValid(false)
                              setPhone(event.target.value)
                            } else {
                              setPhoneValid(true)
                              handleChangeAddress(event.target.value, setPhone)
                            }
                          }}
                          type="tel"
                          placeholder={`${fields_details.phone.placeholder}*`}
                          required
                        />
                        {!phoneValid && <div className="bg-danger font-14 text-center text-white">{`${fields_details.phone.placeholder} inválido`}</div>}
                      </Col>
                      <Col md={6}>
                        <InputMask
                          ref={cpf_ref}
                          mask={cpf.length <= 14 ? '999.999.999-999' : '99.999.999/9999-99'}
                          maskChar={null}
                          className={`form-control mt-2 ${cpfValid ? '' : ' border-danger'}`}
                          value={cpf}
                          onChange={event => {
                            if (!validateCPF(event.target.value) && !validateCNPJ(event.target.value)) {
                              setCpf(event.target.value)
                              setCpfValid(false)
                            } else {
                              setCpfValid(true)
                              handleChangeAddress(event.target.value, setCpf)
                            }
                          }}
                          placeholder="CPF / CNPJ*"
                        />
                        {!cpfValid && <div className="bg-danger font-14 text-center text-white">{invalidDocTranslation}</div>}
                      </Col>
                      {cpf.length === 18 &&
                        <Fragment>
                          <Col md={6}>
                            <Form.Control
                              ref={corporate_name_ref}
                              className="mt-2"
                              value={corporate_name}
                              onChange={event => handleChangeAddress(cleanString(event.target.value), setCorporateName)}
                              placeholder="Razão Social*"
                            />
                          </Col>
                          <Col md={6}>
                            <Form.Control
                              ref={state_registration_ref}
                              className="form-control mt-2"
                              value={state_registration}
                              onChange={event => handleChangeAddress(cleanString(event.target.value), setStateRegistration)}
                              placeholder="Inscrição Estadual"
                            />
                          </Col>
                        </Fragment>
                      }
                    </Row>
                  </Form>
                </section>
                <section className={`mt-4 ${(email !== '' && email) || (cep !== '' && cep) ? 'd-block' : 'd-none'}`}>
                  <div className="title-section d-flex">
                    <div>
                      <h3 className="title-checkout">{shippingInfo}</h3>
                      <small>{shippingInfoSubtitle}</small>
                    </div>
                  </div>
                  <Form className="mt-3">
                    <InputMask
                      ref={cep_ref}
                      mask={zipMask}
                      maskChar={null}
                      className="form-control mt-2"
                      disabled={loading}
                      value={cep}
                      onChange={event => handleChangeCep(event?.target?.value)}
                      placeholder={zipTranslation}
                    />
                    {(addressFull.erro === undefined || addressFull.erro === false || showFields === true) ? '' : <Button className="pl-0" variant="link" onClick={() => setShowFields(true)} > Clique aqui para preencher manualmente </Button>}
                    {((Object.keys(addressFull).length > 0 && addressFull.erro !== true) || showFields === true) ?
                      (<Fragment>
                        {cep.length === 9 &&
                          <div>
                            <Row>
                              <Col lg={8}>
                                <Form.Control
                                  ref={address_logradouro_ref}
                                  value={addressFull.logradouro}
                                  onChange={(event) => handleChangeAddress({ ...addressFull, logradouro: cleanString(event.target.value) }, setAddressFull)}
                                  placeholder="Endereço*"
                                  className="mt-2"
                                />
                              </Col>
                              <Col lg={4}>
                                <Form.Control
                                  ref={inputNumberRef}
                                  className="mt-2"
                                  value={numero}
                                  onChange={event => handleChangeAddress(cleanString(event.target.value), setNumero)}
                                  placeholder="Número*"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={4}>
                                <Form.Control
                                  ref={address_bairro_ref}
                                  className="mt-2"
                                  value={addressFull.bairro}
                                  onChange={(event) => handleChangeAddress({ ...addressFull, bairro: cleanString(event.target.value) }, setAddressFull)}
                                  placeholder="Bairro*"
                                />
                              </Col>
                              <Col lg={4}>
                                <Form.Control
                                  ref={complemento_ref}
                                  className="mt-2"
                                  value={complemento}
                                  onChange={event => handleChangeAddress(cleanString(event.target.value), setComplemento)}
                                  placeholder="Complemento"
                                />
                              </Col>
                              <Col lg={4}>
                                <Form.Control
                                  ref={address_localidade_ref}
                                  className="mt-2"
                                  value={addressFull.localidade}
                                  onChange={(event) => handleChangeAddress({ ...addressFull, localidade: cleanString(event.target.value) }, setAddressFull)}
                                  placeholder="Cidade*"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <Form.Control
                                  as="select"
                                  className="mt-2"
                                  ref={address_country_ref}
                                  value={addressFull.country}
                                  onChange={(event) => handleChangeAddress({ ...addressFull, country: event.target.value }, setAddressFull)}
                                  placeholder="Country*"
                                >
                                  <option key={"BR"} value={"BR"}>{"Brasil"}</option>
                                </Form.Control>
                              </Col>
                              <Col md={6}>
                                {cStateTxtVisible ? (
                                  <InputMask
                                    ref={address_uf_ref}
                                    mask={'aa'}
                                    maskChar={null}
                                    className="form-control mt-2"
                                    disabled={loading}
                                    value={addressFull.uf}
                                    onChange={(event) => handleChangeAddress({ ...addressFull, uf: event.target.value.toUpperCase() }, setAddressFull)}
                                    placeholder={stateTranslation}
                                  />
                                ) : (
                                  <Form.Control
                                    as="select"
                                    className="mt-2"
                                    ref={address_uf_ref}
                                    value={addressFull.uf}
                                    onChange={(event) => handleChangeAddress({ ...addressFull, uf: event.target.value }, setAddressFull)}
                                    placeholder="Estado*"
                                  >
                                    <option value="" disabled>{pickOneTranslation}</option>
                                    {countryStates}
                                  </Form.Control>
                                )}
                              </Col>
                            </Row>
                          </div>
                        }
                        <Row>
                          <Col md={12}>
                            <Form.Control
                              ref={contactName_ref}
                              value={contactName}
                              onChange={event => handleChangeAddress(event.target.value, setContactName)}
                              className="mt-2"
                              placeholder="Nome do Destinatário"
                            />
                          </Col>
                        </Row>
                        {shippingMethods && shippingMethods.rates && shippingMethods.rates.length > 0 &&
                          <div style={{ height: '100%', minHeight: '180px' }} className={numero === '' ? 'd-none' : "pb-3"}>
                            <div className="title-section d-flex mt-4 mb-3">
                              <div>
                                <h3 className="title-checkout">{shippingMethodsTranslation}</h3>
                                <small>{shippingMethodsSubtitle}</small>
                              </div>
                            </div>
                            {shippingMethods && shippingMethods.rates && shippingMethods.rates.filter((rate) => {
                              return !['Taxa Fixa', 'Frete Grátis'].includes(rate.carrier_title)
                            }).map((method, i) => (
                              <Card key={`${i + method.carrier_title}`}>
                                <Card.Body>
                                  {method.rates.map((rate, i) => (
                                    <Fragment key={`${i + rate.carrier}`}>
                                      <Form.Check type="radio" id={`sendRadio${rate.method + i}`} custom>
                                        <Form.Check.Input
                                          name={`sendMethods`}
                                          type="radio"
                                          disabled={loading}
                                          checked={cartData.selected_shipping_rate && cartData.selected_shipping_rate.method === rate.method}
                                          onChange={() => handleSaveShipping(rate.method)}
                                        />
                                        <Form.Check.Label htmlFor={`sendRadio${rate.method + i}`} className="d-flex justify-content-between">
                                          <div className="d-flex flex-column w-50">
                                            <strong> {typeof rate.method_title === 'string' ? rate.method_title.split(' ~ ')[0] : rate.method_title} </strong>
                                            <small className="font-12 d-block">
                                              {typeof rate.method_title === 'string' ? rate.method_title.split(' ~ ')[1] : ''}
                                            </small>
                                          </div>
                                          <div className="d-flex flex-column text-right w-50">
                                            <strong>{parseFloat(rate.price) > 0 ? formatedPrice(rate.price) : 'Grátis'} </strong>
                                            <small className={'font-12 d-block'}>
                                              {typeof rate.method_description === 'string' ? rate.method_description.split(' ~ ')[0] : ''}
                                            </small>
                                          </div>
                                        </Form.Check.Label>
                                      </Form.Check>
                                      <hr className="shipping-separators" />
                                    </Fragment>
                                  ))}
                                </Card.Body>
                              </Card>
                            ))}
                          </div>
                        }
                      </Fragment>) : ''
                    }
                  </Form>
                </section>
                <section className={`pt-4 ${paymentData.methods.length > 0 ? 'd-block' : 'd-none'}`} >
                  <div className="title-section d-flex">
                    <div>
                      <h3 className="title-checkout">{paymentMethodTranslation}</h3>
                      <small>{paymentMethodSubtitle}</small>
                    </div>
                  </div>
                  <Accordion className="mt-3">
                    {paymentData && paymentData.methods.length > 0 && paymentData.methods.map((payment, i) => {
                      if ((payment.method === 'mercado_pago' || payment.method === 'pagseguro' || payment.method === 'flit_pay') && payment?.app?.config?.accepts_credit_card) {
                        return (
                          <Fragment key={'fragment_' + i}>
                            <Card
                              key={'credit_card_' + i}
                              className={payment.app.config && payment.app.config.accepts_credit_card === "1" ? 'd-block' : 'd-none'}
                            >
                              <Accordion.Toggle
                                onClick={() => {
                                  /*
                                  if (loading) {
                                    return false
                                  }
                                  */
                                  setOption('credit_card')
                                  if (payment.method === 'mercado_pago') {
                                    setPublicKey(payment.app.config.public_key)
                                  }
                                  setPaymentMethod(payment.method)
                                  setSelectedOption(i)
                                  handleSavePayment(payment.method)
                                }}
                                as={Card.Header}
                                disabled={loading}
                                eventKey={'Cartão de Crédito' + i}
                                className={`d-flex justify-content-between align-items-center w-100`}
                                style={{ background: `${selectedOption === i ? 'rgba(0, 0, 0, 0.12)' : ''}` }}
                              >
                                {payment.app.config && payment.app.config.accepts_credit_card === "1" ? `Cartão de Crédito` : ''}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey={'Cartão de Crédito' + i}>
                                <Card.Body>
                                  <CreditCard onChange={setCardObject} />
                                  {payment.method === 'pagseguro' &&
                                    <InputMask
                                      mask={'99/99/9999'}
                                      maskChar={null}
                                      value={birthDate}
                                      className="form-control mt-2"
                                      onChange={event => setBirthDate(event.target.value)}
                                      placeholder="Data de Nascimento (DD/MM/AAAA)"
                                      required
                                    />
                                  }
                                  <Form.Control
                                    className="mt-2"
                                    as="select"
                                    style={{ height: '46px' }}
                                    onChange={event => {
                                      setInstallment(event.target.value)
                                    }}
                                    value={installment}
                                  >
                                    {payment.app.config && cartData.installments && cartData.installments.filter(installment => {
                                      return installment.installment <= parseInt(payment.app.config.installments);
                                    }).map((installment) => {
                                      return <option key={installment.installment} value={installment.installment}>
                                        {installment.installment}x de {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(installment.amount)}
                                        {installment.installment <= parseInt(payment.app.config.interest_free_installments) ? ' sem juros' : ` (${formatedPrice(installment.total_amount)})`}
                                      </option>
                                    })}
                                  </Form.Control>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            <Card
                              key={`${payment.method} ${i}`}
                              className={payment.app.config && payment.app.config.accepts_bank_billet === "1" ? 'd-block' : 'd-none'}
                            >
                              <Accordion.Toggle
                                as={Card.Header}
                                disabled={loading}
                                eventKey={'Boleto' + i}
                                className={`d-flex justify-content-between align-items-center w-100`}
                                style={{ background: `${selectedOption === (i + 1) ? 'rgba(0, 0, 0, 0.12)' : ''}` }}
                                onClick={() => {
                                  /*
                                  if (loading) {
                                    return false
                                  }
                                  */
                                  setOption('ticket')
                                  if (payment.method === 'mercado_pago') {
                                    setPublicKey(payment.app.config.public_key)
                                  }
                                  setPaymentMethod(payment.method)
                                  setSelectedOption(i + 1)
                                  handleSavePayment(payment.method)
                                }}
                              >
                                {payment.app.config && payment.app.config.accepts_bank_billet === "1" ? `Boleto` : ''}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey={'Boleto' + i}>
                                <Card.Body>
                                  <Row>
                                    <Col md={12}>
                                      <p className="font-14">Clique em "Finalizar Compra" para gerar o seu boleto.</p>
                                      <p className="font-14">Informações sobre o pagamento via boleto:</p>
                                      <ul>
                                        <li className="font-14">Valor à vista <strong> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(cartData.grand_total)} </strong>;</li>
                                        <li className="font-14">Não pode ser parcelado! Use cartão de crédito para parcelar sua compra;</li>
                                        <li className="font-14">Prazo de até 2 dias úteis para compensar.</li>
                                      </ul>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            {payment.app.config && payment.app.config.accepts_online_debit === '1' &&
                              <Card
                                key={payment.id + i}
                                className={payment.app.config && payment.app.config.accepts_online_debit === "1" ? 'd-block' : 'd-none'}
                              >
                                <Accordion.Toggle
                                  onClick={() => {
                                    /*
                                    if (loading) {
                                      return false
                                    }
                                    */
                                    setOption('online_debit')
                                    if (payment.method === 'mercado_pago') {
                                      setPublicKey(payment.app.config.public_key)
                                    }
                                    setPaymentMethod(payment.method)
                                    setSelectedOption(i + 2)
                                    handleSavePayment(payment.method)
                                  }}
                                  as={Card.Header}
                                  disabled={loading}
                                  eventKey={'Debito' + i}
                                  className={`d-flex justify-content-between align-items-center w-100`}
                                  style={{ background: `${selectedOption === (i + 2) ? 'rgba(0, 0, 0, 0.12)' : ''}` }}
                                >
                                  {payment.app.config && payment.app.config.accepts_online_debit === "1" ? `Débito Online` : ''}
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={'Debito' + i}>
                                  <Card.Body>
                                    <Row>
                                      <Col md={12}>
                                        <p className="font-14">Selecione o banco para poder finalizar a compra.</p>
                                        <Form.Control as="select" value={selectBank} onChange={e => setSelectBank(e.target.value)}>
                                          <option value="ITAU">Banco Itaú</option>
                                          <option value="BANRISUL">Banco Banrisul</option>
                                          <option value="BRADESCO">Banco Bradesco</option>
                                          <option value="BANCO_BRASIL">Banco do Brasil</option>
                                        </Form.Control>
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            }
                          </Fragment>
                        )
                      } else {
                        return (
                          <Fragment key={`payment_${i}`}>
                            <Card>
                              <Accordion.Toggle
                                as={Card.Header}
                                eventKey={payment.method + i}
                                className={`d-flex justify-content-between align-items-center w-100`}
                                style={{ background: `${selectedOption === i ? 'rgba(0, 0, 0, 0.12)' : ''}` }}
                                disabled={loading}
                                onClick={() => {
                                  /*
                                  if (loading) {
                                    return false
                                  }
                                  */
                                  setOption(payment.method)
                                  setPaymentMethod(payment.method)
                                  setSelectedOption(i)
                                  handleSavePayment(payment.method)
                                }}
                              >
                                {payment.method_title}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey={payment.method + i}>
                                <Card.Body>
                                  <Row>
                                    <Col md={12}>
                                      <p className="font-14">Clique em "Finalizar Compra" para gerar o seu pedido.</p>
                                      <p className="font-14">Informações sobre o pagamento:</p>
                                      <ul>
                                        <li className="font-14"> {payment.description} </li>
                                      </ul>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </Fragment>
                        )
                      }
                    })
                    }
                  </Accordion>
                </section>
                {!loading ? '' :
                  <div className="w-100 mt-3 d-flex justify-content-center align-items-center">
                    <Spinner animation="border" role="status" variant="primary" />
                  </div>
                }
                <div className="title-section d-flex mt-3">
                  <h3 className="title-checkout" style={{ marginBottom: 0 }} >{additionalInformation}</h3>
                </div>
                <Form>
                  <Form.Control value={observations} onChange={event => setObservations(event.target.value)} className="mt-3" as="textarea" rows="3" placeholder={obsInformation} />
                </Form>
                <button
                  className="btn btn-primary my-4 d-flex justify-content-center align-items-center w-100"
                  style={{
                    height: '52px',
                    fontSize: '18px',
                    backgroundColor: background_button,
                    borderColor: background_button,
                    color: color_text
                  }}
                  onClick={handleSaveOrder}
                  disabled={loading || loadingOrder}
                >
                  <FaLock className="mr-2" />
                  {finishOrderTranslation}
                  {loadingOrder ? <Spinner animation="border" role="status" variant="light" className="ml-2" style={{ width: '20px', height: '20px' }} /> : ''}
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    cartData: state.cartData,
    config: state.configData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    orderData: (data) => dispatch(orderData(data)),
    responseCartData: (data) => dispatch(responseCartData(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Body);
