import React from 'react';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import { PersistGate } from 'redux-persist/integration/react';
import 'rsuite/dist/styles/rsuite-default.css';
import './assets/css/globalStyle.scss';
import Router from './routes';
import { persistor, store } from './store/index';


function App() {
  return (
    <ToastProvider placement="bottom-left">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router />
        </PersistGate>
      </Provider>
    </ToastProvider>
  );
}

export default App;
