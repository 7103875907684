import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import ReactInputMask from 'react-input-mask';

const currentYear = new Date().getFullYear();
const monthsArr = Array.from({ length: 12 }, (x, i) => {
    const month = i + 1;
    return month <= 9 ? '0' + month : month;
});
const yearsArr = Array.from({ length: 15 }, (_x, i) => currentYear + i);

export default function CForm({
    cardMonth,
    cardYear,
    onUpdateState,
    cardNumberRef,
    cardHolderRef,
    cardDateRef,
    onCardInputFocus,
    onCardInputBlur,
    cardCvv,
    cardCPfRef,
    children
}) {
    const [cardNumber, setCardNumber] = useState('');

    const handleFormChange = (event) => {
        const { name, value } = event.target;

        const lintedValue = value.replace('  ', ' ');

        onUpdateState(name, lintedValue);
    };

    // TODO: We can improve the regex check with a better approach like in the card component.
    const onCardNumberChange = (event) => {
        let { value, name } = event.target;
        let cardNumber = value;
        value = value.replace(/\D/g, '');
        if (/^3[47]\d{0,13}$/.test(value)) {
            cardNumber = value
                .replace(/(\d{4})/, '$1 ')
                .replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
            // diner's club, 14 digits
            cardNumber = value
                .replace(/(\d{4})/, '$1 ')
                .replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        } else if (/^\d{0,16}$/.test(value)) {
            // regular cc number, 16 digits
            cardNumber = value
                .replace(/(\d{4})/, '$1 ')
                .replace(/(\d{4}) (\d{4})/, '$1 $2 ')
                .replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
        }

        onUpdateState(name, cardNumber.trimRight());
        setCardNumber(cardNumber.trimRight());
    };

    const onCvvFocus = (event) => {
      onUpdateState('isCardFlipped', true);
    };

    const onCvvBlur = (event) => {
      onUpdateState('isCardFlipped', false);
    };

    return (
        <div className="card-form">
            <div className="card-top">{children}</div>
            <Row>
              <Col md={12} className="mb-2">
                <Form.Control
                  type="tel"
                  name="cardNumber"
                  autoComplete="off"
                  placeholder="Número do cartão"
                  onChange={onCardNumberChange}
                  maxLength="19"
                  ref={cardNumberRef}
                  onFocus={(e) => onCardInputFocus(e, 'cardNumber')}
                  onBlur={onCardInputBlur}
                  value={cardNumber}
                />
              </Col>
              <Col md={12} className="mb-2">
                <ReactInputMask
                  mask="999.999.999-99"
                  maskChar={null}
                  name="cardCPF"
                  autoComplete="off"
                  placeholder="CPF do Portador do Cartão"
                  className="form-control"
                  onChange={handleFormChange}
                  ref={cardCPfRef}
                  onFocus={(e) => onCardInputFocus(e, 'cardCPF')}
                  onBlur={onCardInputBlur}
                />
              </Col>
              <Col md={12} className="mb-2">
                <Form.Control
                  type="text"
                  autoComplete="off"
                  name="cardHolder"
                  placeholder="Nome impresso no cartão"
                  onChange={handleFormChange}
                  ref={cardHolderRef}
                  onFocus={(e) => onCardInputFocus(e, 'cardHolder')}
                  onBlur={onCardInputBlur}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Control as="select"
                    value={cardMonth}
                    name="cardMonth"
                    className="mb-1"
                    placeholder="Data de validade"
                    style={{ height: '46px' }}
                    onChange={handleFormChange}
                    ref={cardDateRef}
                    onFocus={(e) => onCardInputFocus(e, 'cardDate')}
                    onBlur={onCardInputBlur}
                >
                  <option value="" disabled>Mês</option>

                  {monthsArr.map((val, index) => (
                    <option key={index} value={val}>
                      {val}
                    </option>
                  ))}
                </Form.Control>
              </Col>
              <Col md={4}>
                <Form.Control as="select"
                  name="cardYear"
                  value={cardYear}
                  className="mb-1"
                  style={{ height: '46px' }}
                  onChange={handleFormChange}
                  onFocus={(e) => onCardInputFocus(e, 'cardDate')}
                  onBlur={onCardInputBlur}
                >
                  <option value="" disabled>Ano</option>

                  {yearsArr.map((val, index) => (
                    <option key={index} value={val}>
                      {val}
                    </option>
                  ))}
                </Form.Control>
              </Col>
              <Col md={4}>
                <Form.Control
                  maxLength="4"
                  autoComplete="off"
                  name="cardCvv"
                  placeholder="CVV"
                  onChange={handleFormChange}
                  onFocus={onCvvFocus}
                  onBlur={onCvvBlur}
                  ref={cardCvv}
                />
              </Col>
            </Row>
        </div>
    );
}
