import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { AiOutlineHourglass } from 'react-icons/ai';
import { FiCheckCircle, FiCircle, FiMail, FiShoppingCart, FiTruck } from 'react-icons/fi';
import { RiCloseCircleLine } from 'react-icons/ri';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Timeline } from 'rsuite';
import api from '../../api/api';
import CardProducts from '../../components/CardProducts';
import Footer from '../../components/Footer';
import Header from '../../components/Header';

const Confirmation = () => {
  const [status, setStatus] = useState(1);
  const [order, setOrder] = useState([]);
  const [registerVisible, setRegisterVisible] = useState(true);

  const [password, setPassword] = useState([]);
  const [password_confirmation, setPasswordConfirm] = useState([]);

  const history = useHistory();
  const params = useParams();
  const { addToast } = useToasts();

  useEffect(() => {
    api(`/order_by_payment_token/${params.payment_token}`).then(response => {
      const order_data = response.data.data
      order_data.items = order_data.items.filter(item => item.parent_id === null)
      setOrder(order_data)

      if (order_data.payment_status === 'pending') {
        if (order_data.payment_method === 'ticket' || order_data.payment_method === 'boleto') {
          setStatus(0);
        } else if (order_data.payment_method === 'credit_card' || order_data.payment_method === 'credit') {
          setStatus(1);
        } else if (order_data.payment_method === 'online_debit') {
          setStatus(5);
        } else {
          setStatus(4);
        }
      } else if (order_data.payment_status === 'paid') {
        setStatus(2)
      } else if (order_data.payment_status === 'canceled') {
        setStatus(3)
      }
    }).catch(() => console.log('Erro ao trazer as informações do pedido'))
  }, [history, params.payment_token])

  const handleRegisterNewCustomer = () => {
    api.post('/customer/post-order-password-reset', {
      password,
      password_confirmation
    }, { params: { payment_token: params.payment_token } }).then(response => {
      addToast('Usuário criado com sucesso!', { appearance: 'success', autoDismiss: true })
      setRegisterVisible(false)
    }).catch(() => addToast('Erro ao criar usuário, tente novamente', { appearance: 'error', autoDismiss: true }))
  }

  const paymentStatus = {
    status: {
      'paid': 'Pagamento Confirmado',
      'pending': 'Pagamento Confirmado',
      'canceled': 'Pagamento Cancelado',
      'refunded': 'Pagamento Estornado',
    },
    statusChecked: {
      'paid': 'success',
      'pending': '',
      'canceled': 'cancel',
      'refunded': '',
    },
    statusClassName: {
      'paid': 'checkout',
      'pending': '',
      'canceled': 'cancel',
      'refunded': '',
    },
  }

  const orderStatus = {
    status: {
      'not_processed': 'Aguardando Envio',
      'ready_for_pickup': 'Pronto para Retirada',
      'processing': 'Enviado Parcialmente',
      'completed': 'Pedido Enviado',
      'canceled': 'Pedido Cancelado',
      'closed': 'Pedido Arquivado',
    },
    statusChecked: {
      'not_processed': '',
      'ready_for_pickup': 'success',
      'processing': 'success',
      'completed': 'success',
      'processed': 'success',
      'canceled': 'cancel',
      'closed': '',
    },
    statusClassName: {
      'not_processed': '',
      'ready_for_pickup': 'checkout',
      'processing': 'checkout',
      'completed': 'checkout',
      'processed': 'checkout',
      'canceled': 'cancel',
      'closed': '',
    },
  }

  const readyForPickup = [
    {
      title: 'Pedido realizado',
      subtitle: '',
      date: order.created_at,
      className: 'checkout',
      checked: 'success',
      order: 1
    },
    {
      title: 'Pagamento Confirmado',
      subtitle: '',
      date: order.payment_status === 'paid' ? order.invoices[0].updated_at : '',
      className: paymentStatus.statusClassName[order && order.payment_status],
      checked: paymentStatus.statusChecked[order && order.payment_status],
      order: order.status === 'ready_for_pickup' && order.payment_status === 'pending' ? 3 : order.status === 'completed' && order.payment_status === 'pending' ? 4 : 2
    },
    {
      title: 'Pronto para Retirada',
      subtitle: '',
      date: order.status === 'ready_for_pickup' || order.status === 'processing' || order.status === 'completed' ? order.updated_at : '',
      className: orderStatus.statusClassName[order && order.status],
      checked: orderStatus.statusChecked[order && order.status],
      order: (order.status === 'ready_for_pickup' && order.payment_status === 'pending') || (order.status === 'completed' && order.payment_status === 'pending') ? 2 : 3
    },
    {
      title: 'Produto Retirado',
      subtitle: '',
      date: order.status === 'completed' ? order.updated_at : '',
      className: orderStatus.statusClassName[order && order.status],
      checked: order.status === 'completed' && orderStatus.statusChecked[order && order.status],
      order: (order.status === 'completed' && order.payment_status === 'pending') ? 3 : 4
    }
  ]

  const orderShipments = order.shipments && order.shipments.map((shipment) => {
    return {
      title: orderStatus.status[order && order.shipments[0].id !== shipment.id ? 'processing' : order.status === 'processing' ? 'processing' : 'completed'],
      transport: shipment.items && shipment.items.length > 0 ? shipment.items : '',
      subtitle: order.status === 'processing' || order.status === 'completed' ? `${shipment.track_number !== '' ? 'Código de Rastreio: ' + shipment.track_number : '' } ${shipment.carrier_title !== '' ? '<br> Transportadora: ' + shipment.carrier_title : ''}` : 'O código de rastreamento ficará disponível assim que o pedido for enviado. Por favor, retorne em breve.',
      date: shipment.status === 'processed' ? shipment.updated_at : '',
      className: orderStatus.statusClassName[shipment.status],
      checked: orderStatus.statusChecked[shipment.status],
      order: shipment.status === 'processed' && order.payment_status !== 'pending' && shipment.id
    }
  })

  const orderCompleted = [
    {
      title: 'Pedido realizado',
      subtitle: '',
      date: order.created_at,
      className: 'checkout',
      checked: 'success',
      order: 0
    },
    {
      title: paymentStatus.status[order && order.payment_status],
      subtitle: '',
      date: order.payment_status === 'paid' ? order.invoices[0].updated_at : '',
      className: paymentStatus.statusClassName[order && order.payment_status],
      checked: paymentStatus.statusChecked[order && order.payment_status],
      order: order.payment_status === 'paid' ? 0 : order.shipments !== undefined && order.shipments.length
    },
    ...(orderShipments !== undefined ? orderShipments : []),
    order.status !== 'completed' ?
    {
      title: 'Pedido Enviado',
      subtitle: 'O código de rastreamento ficará disponível assim que o pedido for enviado. Por favor, retorne em breve.',
      date: '',
      className: order.status === 'completed' ? 'd-none' : '',
      checked: '',
      order: order.shipments !== undefined && order.shipments.length > 0 && order.shipments.length
    } : ''
  ]

  const timelineArray = order && order.shipping_method && order.shipping_method.includes('pickup') ? readyForPickup : orderCompleted

  const orderedArray = timelineArray.sort((a, b) => a.order > b.order ? 1 : a.order < b.order ? -1 : 0)

  return (
    <div className={order && order.items && order.items.length > 0 ? '' : 'd-none'}>
      <Header continueBuy={true} />
      <hr className="my-2 m-3"/>
      <Container>
        <Row className="flex-row-reverse">
          <Col sm={12} lg={5}>
            <CardProducts data={order} confirmationScreen={true} />
            <div>
              <div className="desktop-only mt-4" style={{ width: '90%' }}>
                <div className="d-flex">
                  <FiMail size="50" color="#28a745" className="mr-4 pb-4" />
                  <div>
                    <p className="mb-2 font-weight-bold font-14 text-dark">COMO ACOMPANHAR A ENTREGA</p>
                    <small className="font-14">Enviamos um email com link para esta página, aqui você pode acompanhar a evolução da entrega.</small>
                  </div>
                </div>
                <div className="d-flex mt-4">
                  <FiShoppingCart size="25" color="#28a745" className="mr-4" />
                  <div>
                    <div>
                      <strong className="mb-2 font-14 text-dark">INFORMAÇÕES DE ENTREGA</strong>
                      <div>
                        <small className="font-14"> { order.shipping_address && order.shipping_address.email } </small>
                      </div>
                    </div>
                    <div>
                      <strong className="my-2 font-14 text-dark">ENDEREÇO DE COBRANÇA E ENTREGA</strong>
                      { order.shipping_address && <div>
                        <small className="font-14">
                          { order.shipping_address.address1 }, { order.shipping_address.number }
                          { order.shipping_address.address2 && <span>, {order.shipping_address.address2 }</span> }
                        </small> - <small className="font-14">
                          { order.shipping_address.city }, { order.shipping_address.state } ({ order.shipping_address.postcode })
                        </small>
                      </div> }
                    </div>
                    <div>
                      <strong className="mt-2 mb-1 font-14 text-dark">FORMA DE ENTREGA</strong>
                      <div>
                        { order.shipping_method && order.shipping_method.includes('pickup') ?
                          <div><small className="font-14">{ order.shipping_title && `${order.shipping_title.split(' ~ ')[0]}, ${order.shipping_title.split(' ~ ')[1]}` }</small></div> :
                          <div><small className="font-14">{ order.shipping_method_title && order.shipping_method_title }</small></div>
                        }
                        { order.shipping_method && order.shipping_method.includes('pickup') ?
                          <Fragment>
                            <div><small className="font-14">{ order.shipping_description && order.shipping_description.split(' ~ ')[0] }</small></div>
                            <div><small className="font-14">{ order.shipping_description && order.shipping_description.split(' ~ ')[1] }</small></div>
                          </Fragment> : <div><small className="font-14">{ order.shipping_description && order.shipping_description }</small></div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} lg={7}>
            <small>Pedido #{ order.id } </small>
            <Card className="mt-1">
              <Card.Body className="d-flex align-items-center">
                <Row>
                  { status === 0 ?
                    <Col sm={12} lg={12} className="p-4 py-3 d-flex align-items-center">
                      <AiOutlineHourglass size="35" color="#28a745" className="mr-2" />
                      <div>
                        <h4 className="text-dark mb-2 font-18">Aguardando pagamento</h4>
                        Agora, para concluir a compra, realize o pagamento. <br/>
                        <a href={order && order.external_url} target="_blank" rel="noopener noreferrer" className="my-2 p-2 btn btn-primary">VISUALIZAR BOLETO</a> <br/>
                        <small>Se você já pagou, não se preocupe, o status do pedido será atualizado em até 72h.</small>
                      </div>
                    </Col> :
                    status === 5 ?
                    <Col sm={12} lg={12} className="p-4 py-3 d-flex align-items-center">
                      <AiOutlineHourglass size="35" color="#28a745" className="mr-2" />
                      <div>
                        <h4 className="text-dark mb-2 font-18">Aguardando pagamento</h4>
                        Agora, para concluir a compra, realize o pagamento. <br/>
                        <a href={order && order.external_url} target="_blank" rel="noopener noreferrer" className="my-2 p-2 btn btn-primary">VISUALIZAR LINK DE PAGAMENTO</a> <br/>
                        <small>Se você já pagou, não se preocupe, o status do pedido será atualizado em até 72h.</small>
                      </div>
                    </Col> :
                    status === 1 ?
                    <Col sm={12} lg={12} className="p-4 d-flex align-items-center">
                      <AiOutlineHourglass size="35" color="#28a745" className="mr-2" />
                      <div>
                        <h4 className="text-dark mb-2 font-18">Aguardando pagamento</h4>
                        Obrigado pela sua compra, estamos aguardando a confirmação de pagamento da sua operadora de cartão.
                        O status do pedido será atualizado em até 72h.
                      </div>
                    </Col> : status === 2 ?
                    <Col sm={12} lg={12} className="p-4">
                      <FiCheckCircle size="25" color="#28a745" className="mr-3" />
                      Seu pagamento foi confirmado.
                    </Col> : status === 3 ?
                    <Col sm={12} lg={12} className="p-4">
                      <RiCloseCircleLine size="32" color="red" className="mr-3" />
                      Seu pagamento foi cancelado.
                    </Col> : <Col sm={12} lg={12} className="p-4 d-flex align-items-center">
                      <AiOutlineHourglass size="35" color="#28a745" className="mr-2" />
                      <div>
                        <h4 className="text-dark mb-2 font-18">Aguardando pagamento</h4>
                          Obrigado pela sua compra, segue as informações para pagamento.
                          <ul>
                            <li>{ order && order.payment_description }</li>
                          </ul>
                      </div>
                    </Col>
                  }
                </Row>
              </Card.Body>
            </Card>
            <Card className="mt-3">
              <Card.Body className="d-flex align-items-center">
                <div className="p-3 d-flex">
                  <div>
                    <FiTruck size="25" color="#28a745" className="mr-3" />
                  </div>
                  { order.shipping_method && order.shipping_method.includes('pickup') ?
                  `Retirar em: ${order.shipping_title && order.shipping_title.split(' ~ ')[1]}` :
                  `Destino: ${ order.shipping_address && `${order.shipping_address.address1}, ${order.shipping_address.number}${order.shipping_address.address2 ? ', ' + order.shipping_address.address2 : ''}, ${order.shipping_address.neighborhood}, ${order.shipping_address.city} - ${order.shipping_address.state}` }`
                  }
                </div>
              </Card.Body>
            </Card>
            <Card className="border-top-0 mb-4">
              <Card.Body>
                <Timeline className="custom-timeline pl-4 p-3">
                  { orderedArray.map((timeline, i) => {
                    return (
                      <Fragment key={'timeline_' + i}>
                        {timeline !== '' &&
                          <Timeline.Item
                            key={timeline.title + i}
                            className={timeline.className}
                            dot={
                              timeline.checked === 'success' ?
                              <FiCheckCircle size="20" style={{ background: '#fff', color: '#28a745' }} /> :
                              timeline.checked === 'cancel' ?
                              <RiCloseCircleLine size="20" style={{ background: '#fff', color: 'red' }} /> :
                              <FiCircle size="20" style={{ background: '#fff', color: '#ccc' }} />
                            }
                          >
                            <p className="m-0 font-14"> { timeline.title } </p>
                            <small className="font-12"> { timeline.date !== '' && moment(timeline.date).format('DD/MM/YYYY hh:mm:ss A') } </small> <br/>
                            { timeline.transport !== '' && timeline.transport && timeline.transport.length > 0 && timeline.transport.map((item, j) => {
                              return (
                                <Fragment key={'transport_item_' + j}>
                                  <small className="font-12"> { item.qty } x { item.product.name } </small> <br/>
                                </Fragment>
                              )
                            })
                            }
                            <small className="font-12" dangerouslySetInnerHTML={{ __html: timeline.subtitle }} />
                          </Timeline.Item>
                        }
                      </Fragment>
                    )
                  }) }
                </Timeline>
              </Card.Body>
            </Card>
            <Card hidden={(order.customer && order.customer.is_verified === 1) || registerVisible === false} className="mb-3">
              <Card.Header>
                <Card.Title style={{ fontWeight: '300' }} >Cadastre-se agora</Card.Title>
                <Card.Subtitle style={{ fontWeight: '300' }}>Se você ainda não é cadastrado em nossa loja, cadastre-se agora e receba as novidades em primeira mão, basta inserir a senha para se cadastrar em nossa loja.</Card.Subtitle>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col sm={12} lg={6}>
                      <Form.Control type="text" autoComplete="username email" name="username" className="d-none"/>
                      <Form.Control type="password" autoComplete="new-password" value={password} onChange={event => setPassword(event.target.value)} placeholder="Nova senha" />
                    </Col>
                    <Col sm={12} lg={6} className="mt-3 mt-lg-0">
                      <Form.Control type="password" autoComplete="new-password" value={password_confirmation} onChange={event => setPasswordConfirm(event.target.value)} placeholder="Confirmar nova senha" />
                    </Col>
                    <Col sm={12} className="mt-3 text-right">
                      <Button variant="primary" onClick={handleRegisterNewCustomer}>Cadastrar</Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Row className="mobile-only mb-4">
            <Col sm={12} className="d-flex mx-3">
              <FiMail size="25" color="#28a745" className="mr-4" />
              <div className="w-50">
                <p className="mb-2 font-weight-bold font-14 text-dark">COMO ACOMPANHAR A ENTREGA</p>
                <small>Enviamos um email com link para esta página, aqui você pode acompanhar a evolução da entrega.</small>
              </div>
            </Col>
            <Col sm={12} className="d-flex mt-4 mx-3">
              <FiShoppingCart size="25" color="#28a745" className="mr-4" />
              <div className="d-flex flex-column">
                <strong className="mb-2 font-14 text-dark">INFORMAÇÕES DE ENTREGA</strong>
                <small className="font-14"> { order.shipping_address && order.shipping_address.email } </small>
                <strong className="my-2 font-14 text-dark">ENDEREÇO DE COBRANÇA E ENTREGA</strong>
                <small className="font-14"> { order.shipping_address && order.shipping_address.address1 }, { order.shipping_address && order.shipping_address.number } </small>
                <small className="font-14">{ order.shipping_address && order.shipping_address.city }, { order.shipping_address && order.shipping_address.state } ({ order.shipping_address && order.shipping_address.postcode })</small>
                <strong className="mt-2 mb-1 font-14 text-dark">FORMA DE ENTREGA</strong>
                { order.shipping_method && order.shipping_method.includes('pickup') ?
                  <small className="font-14">{ order.shipping_title && `${order.shipping_title.split(' ~ ')[0]}, ${order.shipping_title.split(' ~ ')[1]}` }</small> :
                  <small className="font-14">{ order.shipping_method_title && order.shipping_method_title }</small>
                }
                {
                  order.shipping_method && order.shipping_method.includes('pickup') ?
                  <Fragment>
                      <small className="font-14">{ order.shipping_description && order.shipping_description.split(' ~ ')[0] }</small>
                      <small className="font-14">{ order.shipping_description && order.shipping_description.split(' ~ ')[1] }</small>
                  </Fragment> : <small className="font-14">{ order.shipping_description && order.shipping_description }</small>
                }
              </div>
            </Col>
          </Row>
        </Row>
      </Container>
      <Footer data ={order} />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    orderData: state.orderData
  }
}

export default connect(mapStateToProps)(Confirmation);