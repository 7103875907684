import React, { useEffect, Fragment } from 'react'
import ImgPgtoSeg from '../../assets/images/header/pagamento.svg'
import { Container } from 'react-bootstrap'
import { FiArrowRight } from 'react-icons/fi'
import { connect } from 'react-redux'
import { configData } from '../../store/actions/configDataAction'
import api from '../../api/api'
import { Helmet } from 'react-helmet'
import ReactHtmlParser from 'react-html-parser'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import TagManager from 'react-gtm-module'

const Header = ({ continueBuy, config, configData }) => {

  useEffect(() => {
    api('/admin/config').then(response => {
      configData(response.data.data)
    }).catch(() => console.log('Erro ao trazer os dados de configuração do lojista'))
  }, [configData])

  let titlePage = 'Checkout'

  if (typeof config.owner_name !== 'undefined') {
    titlePage = config.owner_name
  }
  const background = config?.json_settings?.general?.checkout?.header?.background_color

  useEffect(() => {
    if (!config.id) {
      return
    }

    if (config?.google_analytics_tracking_id) {
      ReactGA.initialize(config.google_analytics_tracking_id, {
        debug: false
      })
      ReactGA.pageview(window.location.pathname + window.location.search)
    }

    if (config?.google_tag_manager_id) {
      TagManager.initialize({ gtmId: config.google_tag_manager_id })
    }

    if (config?.facebook_pixel_number) {
      const options = {
        autoConfig: true,
        debug: false,
      }

      ReactPixel.init(config.facebook_pixel_number, null, options)
      ReactPixel.pageView()
    }
  }, [config])


  return (
    <Fragment>
      <div
        style={{
          backgroundColor: background,
          height: "90px",
          display: "flex",
        }}
      >
        <Helmet titleTemplate="Checkout - %s" >
          <title> { titlePage } </title>
          <link rel="icon" href={(config && config.favicon_url) || ""} />
          { config && config.meta_instagram !== null && ReactHtmlParser(config.meta_instagram) }

          { config && config.jivochat_widget_id !== null && config.jivochat_widget_id !== '' &&
            <script type='text/javascript'>
              {process.browser && (function () {
                var widget_id = `${config.jivochat_widget_id}`; var d = document; var w = window; function l() {
                  var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://code.jivosite.com/script/widget/' + widget_id; var ss = document.getElementsByTagName('script')[0]; ss.parentNode.insertBefore(s, ss);
                } if (d.readyState === 'complete') { l(); } else { if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } }
              })()}</script>
          }
        </Helmet>
        <Container
          className="d-flex justify-content-between align-items-center"
        >
          <img src={config.logo_url} alt="logo" className="img-fluid img-header" style={{ maxWidth: '230px', maxHeight: '44px' }} />
          <img src={ImgPgtoSeg} alt="Pagamento seguro" hidden={continueBuy ? true : false} width="105"/>
          <a href="/" className="font-14" style={{ color: '#000' }} hidden={continueBuy ? false : true}>
            Continue comprando
            <FiArrowRight />
          </a>
        </Container>
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    config: state.configData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    configData: data => dispatch(configData(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
